/*=========================================================================================
  File Name: moduleCountryMutations.js
  Description: Country Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  ADD_ITEM(state, item) {
    debugger;
    state.PushAllNotifications.unshift(item)
  },
  SET_PushAllNotifications(state, PushAllNotifications) {
    debugger;
    try {
      //const parsedData = JSON.parse(countries);
      state.PushAllNotifications = PushAllNotifications;
      //state.OutbreakCountries =  countries;
    } catch (error) {
      console.error("Failed to parse PushAllNotification data JSON:", error);
      state.PushAllNotifications = [];
    }
  },
  UPDATE_PushAllNotification(state, PushAllNotification) {
 
    const PushAllNotificationIndex = state.PushAllNotifications.findIndex((p) => p.Id == PushAllNotification.Id)
    Object.assign(state.PushAllNotifications[PushAllNotificationIndex], PushAllNotification)
  },
  REMOVE_ITEM(state, itemId) {
      const ItemIndex = state.PushAllNotifications.findIndex((p) => p.Id == itemId)
      state.PushAllNotifications.splice(ItemIndex, 1)
  },
}
