<template>
  <vs-sidebar position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4 style="color: white;">
        {{ Object.entries(this.data).length === 0 ? 'اضافه اشعار جديده' : 'تعديل الاشعار' }}
      </h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>

    <vs-divider class="mb-0"></vs-divider>

    <VuePerfectScrollbar class="scroll-area--data-list-add-new" :settings="settings">
      <div class="p-6">
        <!-- NAME -->
        <label style="font-weight: bold; color: white;">محتوي الاشعار</label>
        <vs-input v-model="Name" class="mt-5 w-full" name="name" />
        <span class="text-danger text-sm" v-show="errors.has('name')">{{ errors.first('name') }}</span>
      </div>

      <div class="p-6">
        <vs-input
          type="file"
          @change="AttachmentUploaded"
          accept="image/*"
          name="Image"
        />
        <!-- Display the uploaded image -->
        <div v-if="ImageUrl" class="mt-2">
          <img :src="`${baseURL}${tr.Image}`" alt="Uploaded Image" class="w-24 h-24 rounded" />
        </div>
      </div>

      <div class="flex flex-wrap items-center p-6">
        <vs-button
          class="mr-6"
          @click="submitData"
          :disabled="!isFormValid"
          style="background-color: aliceblue; color: black; font-weight: bold;"
          color="info"
        >
          {{$t("Send")}}
        </vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Cancel")}}</vs-button>
      </div>
    </VuePerfectScrollbar>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { domain } from "@/gloabelConstant.js";
export default {
  components: {
    VuePerfectScrollbar
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        debugger
        let { Id, Name, Image } = JSON.parse(JSON.stringify(this.data));
        this.Id = Id;
        this.Name = Name;
        this.ImageUrl = Image; // Use ImageUrl to display the image
      }
    }
  },
  data() {
    return {
      baseURL: domain,
      Id: 0,
      Name: "",
      ImageUrl: "", // To store the uploaded image URL
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    };
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit("closeSidebar");
        }
      }
    },
    isFormValid() {
      return !this.errors.any() && this.Name ;
    }
  },
  methods: {
    initValues() {
      if (this.data.Id) return;
      this.Id = 0;
      this.Name = "";
      this.ImageUrl = ""; // Reset the image URL
    },
    AttachmentUploaded(input) {
      if (input.target.files && input.target.files[0]) {
        const file = input.target.files[0];
        this.$vs.loading();
        this.$store
          .dispatch("PushAllNotificationList/UploadNewImage", file)
          .then((res) => {
            if (res.status == 200) {
               this.ImageUrl = res.data.replace(/\/\//g, '/').replace(/^\//, ''); // Assuming res.data contains the image URL
              this.$vs.loading.close();
            }
          })
          .catch(() => {
            this.$vs.loading.close();
            window.showError();
          });
      }
    },
    submitData() {
      debugger;
      this.$validator.validateAll().then(result => {
        if (result) {
          const obj = {
            Id: this.Id,
            Name: this.Name,
            Image: this.ImageUrl // Save the image URL
          };
          if (this.Id !== null && this.Id > 0) {
            this.$store.dispatch("PushAllNotificationList/updateItem", obj).then(() => {
                this.$store.dispatch("PushAllNotificationList/fetchDataListItems");
               //location.reload();
                window.showSuccess();
                
              })
              .catch((err) => {
                console.error(err);
              });
          } else {
            delete obj.ID;
            obj.popularity = 0;
            this.$store
              .dispatch("PushAllNotificationList/addItem", obj)
              .then(() => {
                
                this.$store.dispatch("PushAllNotificationList/fetchDataListItems");
                
                //location.reload();
                window.showSuccess();
                
              })
              .catch((err) => {
                console.error(err);
              });
          }
          this.$emit("closeSidebar");
          this.initValues();
        }
      });
      //location.reload();
    },
  },
  created() {},
  mounted() {
    this.isMounted = true;
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  height: calc(100% - 4.3rem);
}
</style>