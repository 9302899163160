/*=========================================================================================
  File Name: moduleCountryActions.js
  Description: Country Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "@/axios.js"

export default {
  addItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.post("API/PushAllNotification/Add", item,{headers:Token})
        .then((response) => {
          console.log("response",response);
          commit('ADD_ITEM', Object.assign(item, { ID: response.data.data.ID }))
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  fetchDataListItems({ commit }) {
    debugger;
    
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.get("API/PushAllNotification/GetAll",{headers:Token})
        .then((response) => {
          debugger;
          commit('SET_PushAllNotifications', response.data.data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  updateItem({ commit }, item) {
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.put("API/PushAllNotification/Update", item,{headers:Token})
        .then((response) => {
          commit('UPDATE_PushAllNotification', item)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },

  GetItemByID({ commit }, itemid) {
    return new Promise((resolve, reject) => {
      axios.get("API/PushAllNotification/GetByID?Id=" + itemid)
        .then((response) => {
          commit('UPDATE_Country', response.data.Data)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
  UploadNewImage(context, file) {
    debugger;
    const formData = new FormData();
    formData.append("file",file, file.name);
    return new Promise((resolve, reject) => {
      axios
        .post("/API/Common/UploadImage", formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(response => {
          if (response.status == 200) {
            resolve(response);
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  removeItem({ commit }, item) {
    debugger;
    var Token={Authorization: 'Bearer ' + localStorage.accessToken};
    return new Promise((resolve, reject) => {
      axios.delete("API/PushAllNotification/Delete?PushAllNotificationId="+item.Id,{headers:Token})
        .then((response) => {
          commit('REMOVE_ITEM', item.Id)
          resolve(response)
        })
        .catch((error) => { reject(error) })
    })
  },
}
